<template>
    <div>
        <md-dialog
            :md-active.sync="showDialog"
            :md-fullscreen="false"
            class="popup-dialog"
        >
            <update-language
                :id="language_id"
                templateMode="true"
                @close="showDialog=false"
            />
        </md-dialog>
        <md-table v-model="languages">
            <md-table-toolbar>
                <h1 class="md-title">Languages</h1>
            </md-table-toolbar>
            <md-table-row slot="md-table-row" slot-scope="{ item }">
                <md-table-cell md-label="ID" md-sort-by="id" md-numeric><a href="#" @click.prevent="update(item)">{{item.id}}</a></md-table-cell>
                <md-table-cell md-label="Language" md-sort-by="id">{{item.language}}</md-table-cell>
                <md-table-cell md-label="Similar languages" md-sort-by="name">{{ item.similar_language }}</md-table-cell>
            </md-table-row>
        </md-table>
        <md-button class="md-fab md-primary md-fab-bottom-right" @click="add">
            <md-icon>add</md-icon>
        </md-button>
    </div>
</template>

<script>
import UpdateLanguage from '@/pages/Admin/UpdateLanguage';
export default {
    components: {UpdateLanguage},
    name: 'Language',
    data() {
        return {
            showDialog: false,
            language_id: null
        }
    },
    computed: {
        languages() {
            return this.$store.getters['translation/languages']
        }
    },
    created() {
        this.$store.dispatch('translation/loadLanguages')
    },
    methods: {
        deleteItem() {
            // in progress
        },
        update(item) {
            this.language_id = item.id
            this.showDialog = true
        },
        add() {
            this.language_id = 0
            this.showDialog = true
            // pass
        },
    }
}
</script>